import React , {Component} from 'react'
import Layout from '../layouts/index'
import DetailHeader from '../components/detailHeader/detailHeader'
import Agency from '../components/aboutUs/agency'
import Seo from "../components/seo/seo"

class Drupal extends Component {
    render() {
        const url = this.props.location.href;

        return (
            <Layout>
                <Seo title="L'agence" url={url}/>
                <DetailHeader title="L'agence"/>
                <Agency/>
            </Layout>
        );
    }
}

export default Drupal
