import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import './aboutUs.scss'

const Agency = () => (
    <StaticQuery
        query={graphql`
            query{
                allNodeAgency{
                    edges {
                    node {
                        id 
                        title
                        body {
                        value
                        } 
                        relationships {
                        field_media_img {
                            relationships {
                            field_media_image {
                                localFile {
                                childImageSharp {
                                    fluid(maxWidth:940, maxHeight:450, quality:90, cropFocus: ENTROPY) {
                                        src
                                    }
                                }
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
            }
        `}

        render={data =>(
            <div className="wd-aboutus">
                <div className="wd-aboutus__container">
                    {data.allNodeAgency.edges.map(({ node }) => (
                        <div className="wd-aboutus__about" key={node.id}>
                            <div className="wd-aboutus__title-desc-box">
                                <h2 className="wd-aboutus__title">{node.title}</h2>
                                <div className="wd-aboutus__description" dangerouslySetInnerHTML={{ __html: node.body.value }}></div>
                            </div>
                            {node.relationships.field_media_img.relationships.field_media_image != null && 
                                <Img className="wd-aboutus__img" fluid={node.relationships.field_media_img.relationships.field_media_image.localFile.childImageSharp.fluid}/>
                            }  
                        </div>
                    ))}
                </div>
            </div>
        )}
    />
)

export default Agency
